import Link from 'next/link';

import UserMenuItem from 'components/header/components/user-menu-item';
import { useMessaging } from 'app/[locale]/messages/context/messaging-context';
import { LEFT_MENU_ITEMS, RIGHT_MENU_ITEMS, MENU_ITEMS } from 'components/header/constants';
import { IMenuItem } from 'components/header/types';
import { useTranslation } from 'i18n/client';
import { IUser } from 'common/types';
import { Button, Icon, Logo } from 'components';
import { routesConstants, helpers, constants } from 'common';
import { Icons } from 'components/icon/types';
import { isSelectedMenuItem, isSearchHidden } from 'components/header/helpers/header-helpers';

interface IProps {
    lang: string;
    userDetails?: IUser;
    handleLogout: () => void;
    handleGoToCreateProductPage: () => void;
    token?: string;
    isProductPage?: boolean;
    pathname: string;
    category?: string;
    isUserLoggedIn?: boolean;
    getTosUrl: () => Promise<string>;
    handleSetNotLoggedInModalData: (_title: string, _message: string) => void;
    handleGoToBanners: () => void;
}

const { routes, prefixes } = routesConstants;
const { cn } = helpers;

const DesktopHeader = ({
    lang,
    userDetails,
    handleLogout,
    handleGoToCreateProductPage,
    isProductPage,
    pathname,
    category,
    isUserLoggedIn,
    getTosUrl,
    handleSetNotLoggedInModalData,
    handleGoToBanners,
}: IProps) => {
    const { t } = useTranslation(lang, 'header');
    const { underMessagesCount } = useMessaging();

    const handleOpenRules = async () => {
        const path = await getTosUrl();

        const newTab = window.open(path, '_blank');

        if (newTab) {
            newTab.focus();
        }
    };

    const getMenuItem = (item: IMenuItem) => {
        if (item.isMessages) {
            return (
                <Link href={`/${lang}${item.path}`} className="text-sm-title-light font-normal text-grey-300">
                    <div className="flex items-center">
                        <div className="relative">
                            {item.icon && <Icon name={item.icon as Icons} className="mr-12" />}
                            {underMessagesCount ? (
                                <div
                                    className={cn(
                                        'absolute rounded-[50%] w-[16px] h-[16px] bg-red-200 text-white-main text-[12px] flex items-center justify-center',
                                        underMessagesCount > 100 ? 'w-[20px] h-[20px] bottom-[-7px] right-[1px]' : 'w-[16px] h-[16px] bottom-0 right-[8px]',
                                    )}
                                >
                                    {underMessagesCount}
                                </div>
                            ) : null}
                        </div>
                        {t(item.label)}
                    </div>
                </Link>
            );
        }

        return (
            <Link href={`/${lang}${item.path}`} className="text-sm-title-light font-normal text-grey-300">
                <div className="flex items-center">
                    {item.icon && <Icon name={item.icon as Icons} className="mr-12" />}
                    {t(item.label)}
                </div>
            </Link>
        );
    };

    return (
        <>
            <div className="w-full border-b border-b-grey-800">
                <div className="container m-[auto] flex min-h-[52px] items-center">
                    <div className="w-1/2 flex items-center">
                        <div className="mr-32">
                            <Logo lang={lang} />
                        </div>
                        {LEFT_MENU_ITEMS.map((item: IMenuItem, index: number) => {
                            if (item.isRules) {
                                return (
                                    <p key={`left-menu-item-${index}`} onClick={handleOpenRules} className="mr-32 text-sm-title-light font-normal text-grey-300 cursor-pointer">
                                        {t(item.label)}
                                    </p>
                                );
                            }

                            if (item.isBanners && isUserLoggedIn) {
                                return (
                                    <p key={`left-menu-item-${index}`} onClick={handleGoToBanners} className="mr-32 text-sm-title-light font-normal text-grey-300 cursor-pointer">
                                        {t(item.label)}
                                    </p>
                                );
                            }

                            if (item.isSupport && !isUserLoggedIn) {
                                return (
                                    <a href={`mailto:${constants.SUPPORT_EMAIL}`} key={`left-menu-item-${index}`} className="mr-32 text-sm-title-light font-normal text-grey-300">
                                        {t(item.label)}
                                    </a>
                                );
                            }

                            if (!item.path || (item.isProtected && !isUserLoggedIn)) {
                                return null;
                            }

                            return (
                                <Link key={`left-menu-item-${index}`} href={`/${lang}${item.path}`} className="mr-32 text-sm-title-light font-normal text-grey-300">
                                    {t(item.label)}
                                </Link>
                            );
                        })}
                    </div>
                    <div className="w-1/2 ml-[auto] flex items-center justify-end">
                        {RIGHT_MENU_ITEMS.map((item: IMenuItem, index: number) => {
                            if (item.path) {
                                return (
                                    <div key={`right-menu-item-${index}`} className={cn('px-32', item.isProfile ? '!pr-[0px]' : 'border-r border-r-grey-800')}>
                                        {isUserLoggedIn && item.isProfile && (
                                            <div className="flex items-center">
                                                <UserMenuItem
                                                    lang={lang}
                                                    userDetails={userDetails}
                                                    handleLogout={handleLogout}
                                                    isUserLoggedIn={isUserLoggedIn}
                                                    handleSetNotLoggedInModalData={handleSetNotLoggedInModalData}
                                                />
                                            </div>
                                        )}
                                        {!item.isProfile &&
                                            (isUserLoggedIn ? (
                                                getMenuItem(item)
                                            ) : (
                                                <div
                                                    className="flex items-center cursor-pointer"
                                                    onClick={() => {
                                                        if (item.title && item.message) {
                                                            handleSetNotLoggedInModalData(item.title, item.message);
                                                        }
                                                    }}
                                                >
                                                    {item.icon && <Icon name={item.icon as Icons} className="mr-12" />}
                                                    {t(item.label)}
                                                </div>
                                            ))}
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </div>
                    {!isUserLoggedIn && (
                        <Link href={routes[prefixes.login].path} className="ml-[auto] text-sm-title-light font-normal text-green-100 whitespace-nowrap">
                            {t('LOGIN')}
                        </Link>
                    )}
                </div>
            </div>
            {!isSearchHidden(pathname) && (
                <div className="container m-[auto] border-b flex items-center border-b-grey-800">
                    <div className="w-[90px]"></div>
                    <div className="flex m-[auto]">
                        {MENU_ITEMS.map((item: IMenuItem, index: number) => {
                            const isSelected = isSelectedMenuItem(item.path, pathname, category, item.query, item.skipSelected);
                            const path = `/${lang}${item.path}${item.query ? `?slug=${item.query}` : ''}`;

                            if (item.path) {
                                return (
                                    <div key={`menu-item-${index}`} className={cn('p-32', isSelected ? 'border-b-[3px] border-b-green-100' : '')}>
                                        <Link href={path} className={cn('text-sm-title-light font-normal', isSelected ? 'text-green-100' : 'text-grey-300')}>
                                            <div className="flex items-center">
                                                {item.icon && <Icon name={item.icon as Icons} className={cn('mr-12', isSelected ? 'green-icon' : '')} />}
                                                {t(item.label)}
                                            </div>
                                        </Link>
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </div>
                    {!isProductPage && (
                        <Button variant="secondary" className="!border !border-solid !border-green-100 min-w-[180px]" onClick={handleGoToCreateProductPage}>
                            {t('CREATE_PRODUCT')}
                        </Button>
                    )}
                </div>
            )}
        </>
    );
};

export default DesktopHeader;
