import { IMenuItem } from 'components/header/types';

export const HOME_FILTER_OPTIONS = {
    cars: {
        value: 'cars',
    },
    realestates: {
        value: 'realestates',
    },
    technology: {
        value: 'technology',
    },
};

export const LEFT_MENU_ITEMS: IMenuItem[] = [
    {
        label: 'ABOUT_US',
        path: '/about',
    },
    {
        label: 'COMMERCIALS',
        path: '/banners',
        isProtected: true,
        isBanners: true,
    },
    {
        label: 'RULES',
        path: '',
        isRules: true,
    },
    {
        label: 'SUPPORT',
        path: '',
        isSupport: true,
    },
];

export const RIGHT_MENU_ITEMS: IMenuItem[] = [
    {
        label: 'MESSAGES',
        path: '/messages',
        icon: 'message',
        title: 'NOT_LOGGED_IN_MESSAGES_TITLE',
        message: 'NOT_LOGGED_IN_MESSAGES_SUBTITLE',
        isMessages: true,
    },
    {
        label: 'ORDERS',
        path: '/orders/sells',
        icon: 'order',
        title: 'NOT_LOGGED_IN_ORDERS_TITLE',
        message: 'NOT_LOGGED_IN_ORDERS_SUBTITLE',
    },
    {
        label: '',
        path: '/',
        isProfile: true,
    },
    {
        label: 'LOGOUT',
        path: '',
        isLogout: true,
    },
];

export const MENU_ITEMS: IMenuItem[] = [
    {
        label: 'SEARCH',
        path: '/',
        icon: 'search',
        skipSelected: true,
    },
    {
        label: 'CARS',
        path: '/',
        icon: 'car',
        query: HOME_FILTER_OPTIONS.cars.value,
    },
    {
        label: 'REAL_ESTATES',
        path: '/',
        icon: 'building',
        query: HOME_FILTER_OPTIONS.realestates.value,
    },
    {
        label: 'TECHNOLOGY',
        path: '/',
        icon: 'monitor',
        query: HOME_FILTER_OPTIONS.technology.value,
    },
];

export const VERIFY_TYPES = {
    user: 'user',
    address: 'address',
    payment: 'payment',
};
